function Privacy() {
  return (
    <div
      style={{
        textAlign: "center",
        padding: "10px 50px 50px",
      }}
    >
      <div
        style={{
          textAlign: "left",
          color: "white",
          textDecoration: "none",
        }}
      >
        <a
          href={"/privacy-en"}
          style={{
            color: "white",
            textDecoration: "none",
          }}
        >
          English
        </a>
      </div>
      <h1>개인 정보 정책</h1>
      <div
        style={{
          textAlign: "left",
        }}
      >
        Art Sharing(이하 "앱")에 오신 것을 환영합니다. 본 개인정보 보호정책은 당사가 귀하의 개인정보를 수집, 사용 및 보호하는 방법을 설명합니다. 앱에 액세스하거나 앱을 사용함으로써 귀하는 본 개인정보 보호정책에 따라 귀하의 개인정보를
        수집, 사용 및 공개하는 데 동의하게 됩니다.
        <br />
        <br />

        <h3>정보 수집</h3>

        당사는 귀하의 사용자 이름, 이메일 주소, 프로필 사진, 위치 데이터를 포함하여 귀하로부터 특정 정보를 수집할 수 있습니다. 당사는 이 정보를 사용하여 앱 내에서 귀하에게 개인화된 기능과 서비스를 제공합니다.
        또한 당사는 기기 유형, 운영 체제, 사용 통계 등의 사용자 정보를 수집하기 위해 Google Analytics를 사용할 수도 있습니다. 이 정보는 앱을 개선하고 사용자에게 더 나은 서비스를 제공하는 데 도움이 됩니다.
        <br />
        <br />

        <h3>정보의 이용</h3>

        당사는 앱 기능을 개선 및 최적화하고, 더 나은 사용자 경험을 제공하고, 새로운 기능과 서비스를 개발하기 위해 수집한 정보를 사용할 수 있습니다. 또한 당사는 앱과 관련된 업데이트, 프로모션 및 기타 관련 정보를 귀하에게 전달하기 위해 이 정보를
        사용할 수도 있습니다.
        <br />
        <br />

        <h3>정보 공개</h3>

        당사는 귀하의 동의 없이 귀하의 개인정보를 제3자에게 판매하거나 공유하지 않습니다. 그러나 법률, 법원 명령 또는 정부 기관에서 요구하는 경우 귀하의 정보를 공개할 수 있습니다.
        <br />
        <br />

        <h3>보안</h3>

        당사는 귀하의 정보 보안을 중요하게 생각하며 무단 접근이나 공개로부터 귀하의 정보를 보호하기 위해 합당한 조치를 취합니다. 그러나 인터넷이나 전자 저장을 통한 전송 방법은 완전히 안전하지 않으며 당사는 귀하의 정보에 대한 절대적인 보안을 보장할 수
        없습니다.
        <br />
        <br />

        <h3>제3자 링크</h3>

        앱에는 당사가 소유하거나 통제하지 않는 제3자 웹사이트 또는 서비스에 대한 링크가 포함될 수 있습니다. 당사는 이러한 제3자 웹사이트 또는 서비스의 개인정보 보호 관행이나 콘텐츠에 대해 책임을 지지 않으며 귀하가 해당 개인정보 보호정책을 검토할
        것을 권장합니다.
        <br />
        <br />

        <h3>아동의 개인정보 보호</h3>

        이 앱은 13세 미만의 어린이를 대상으로 하지 않습니다. 당사는 13세 미만의 어린이로부터 고의로 개인 정보를 수집하지 않습니다. 귀하가 부모 또는 보호자이고 귀하의 자녀가 당사에 개인 정보를 제공했다고 생각하는 경우 당사에 문의하십시오. 아래에
        제공된 이메일 주소로.
        <br />
        <br />

        <h3>개인정보 보호정책 변경</h3>
        당사는 사전 통지 없이 언제든지 본 개인정보 보호정책을 수정할 수 있습니다. 그러한 수정 후에도 앱을 계속 사용하면 수정된 개인정보 보호정책에 동의하는 것으로 간주됩니다.
        <br />
        <br />

        <h3>문의하기</h3>

        본 개인정보 보호정책에 대해 질문이나 우려사항이 있거나 개인정보 보호 권리를 행사하고 싶다면 [mippylabs@gmail.com]으로 문의하시기 바랍니다.
      </div>
    </div>
  );
}

export default Privacy;
