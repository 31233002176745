import React from "react";

function App() {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img src={"/logo.png"} width={300} alt={"logo"} />
      <a
        href={"/delete"}
        style={{
          padding: "50px 0px 30px",
          fontSize: "20px",
          color: "red",
          cursor: "pointer",
          textDecoration: "none",
        }}
      >
        Delete account
      </a>
      <a
        href={"/privacy"}
        style={{
          padding: "20px 0px 0px",
          fontSize: "20px",
          cursor: "pointer",
          textDecoration: "none",
          color: "white",
        }}
      >
        Privacy Policy
      </a>
      <a
        href={"/terms"}
        style={{
          padding: "10px 0px 0px",
          fontSize: "20px",
          cursor: "pointer",
          textDecoration: "none",
          color: "white",
        }}
      >
        Terms & Conditions
      </a>
    </div>
  );
}

export default App;
