import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Delete from "./delete";
import Privacy from "./privacy";
import Terms from "./terms";
import PrivacyEn from "./privacy_en";
import TermsEn from "./terms_en";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/delete" element={<Delete />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/privacy-en" element={<PrivacyEn />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/terms-en" element={<TermsEn />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
);
