function Terms() {
  return (
    <div
      style={{
        textAlign: "center",
        padding: "10px 50px 50px",
      }}
    >
      <div
        style={{
          textAlign: "left",
          color: "white",
          textDecoration: "none",
        }}
      >
        <a
          href={"/terms-en"}
          style={{
            color: "white",
            textDecoration: "none",
          }}
        >
          English
        </a>
      </div>
      <h1>이용약관</h1>
      <div
        style={{
          textAlign: "left",
        }}
      >
        Art Sharing(이하 "앱")에 오신 것을 환영합니다. 앱에 액세스하거나 앱을 사용함으로써 귀하는 다음 이용 약관("이용 약관")을 준수할 것에 동의하게 됩니다. 본 이용 약관에 동의하지 않으면 앱을 사용할 수 없습니다.
        <br />
        <br />

        <h3>정보 수집</h3>
        앱은 귀하의 사용자 이름, 이메일 주소, 프로필 사진, 위치 데이터를 포함하여 귀하로부터 특정 정보를 수집할 수 있습니다. 당사는 이 정보를 사용하여 앱 내에서 귀하에게 개인화된 기능과 서비스를 제공합니다. 또한 당사는 기기 유형, 운영 체제, 사용 통계
        등의 사용자 정보를 수집하기 위해 Google Analytics를 사용할 수도 있습니다. 이 정보는 앱을 개선하고 사용자에게 더 나은 서비스를 제공하는 데 도움이 됩니다.
        <br />
        <br />

        <h3>정보의 이용</h3>
        당사는 앱 기능을 개선 및 최적화하고, 더 나은 사용자 경험을 제공하고, 새로운 기능과 서비스를 개발하기 위해 수집한 정보를 사용할 수 있습니다. 또한 당사는 앱과 관련된 업데이트, 프로모션 및 기타 관련 정보를 귀하에게 전달하기 위해 이 정보를 사용할
        수도 있습니다.
        <br />
        <br />

        <h3>정보 공개</h3>
        당사는 귀하의 동의 없이 귀하의 개인정보를 제3자에게 판매하거나 공유하지 않습니다. 그러나 법률, 법원 명령 또는 정부 기관에서 요구하는 경우 귀하의 정보를 공개할 수 있습니다.
        <br />
        <br />

        <h3>보안</h3>
        당사는 귀하의 정보 보안을 중요하게 생각하며 무단 접근이나 공개로부터 귀하의 정보를 보호하기 위해 합당한 조치를 취합니다. 그러나 인터넷이나 전자 저장을 통한 전송 방법은 완전히 안전하지 않으며 당사는 귀하의 정보에 대한 절대적인 보안을 보장할 수
        없습니다.
        <br />
        <br />

        <h3>이용약관 변경</h3>
        당사는 사전 통지 없이 언제든지 본 이용 약관을 수정할 수 있습니다. 그러한 수정 후에도 앱을 계속 사용하면 수정된 이용 약관에 동의하는 것으로 간주됩니다.
        <br />
        <br />

        <h3>부인 성명</h3>
        앱은 "있는 그대로" 제공되며 당사는 앱의 가용성, 정확성, 신뢰성 또는 모든 목적에 대한 적합성과 관련하여 명시적이든 묵시적이든 어떠한 종류의 진술이나 보증도 하지 않습니다. 우리는 앱에 오류가 없거나 중단되지 않을 것이라고 보증하지 않습니다.
        <br />
        <br />

        <h3>책임의 제한</h3>
        어떠한 경우에도 당사는 이익, 데이터 또는 기타 무형적 손실을 포함하되 이에 국한되지 않고 앱 사용과 관련하여 발생하는 직접적, 간접적, 우발적, 특별 또는 결과적 손해에 대해 책임을 지지 않습니다. 당사가 그러한 손해의 가능성에 대해 통보받은 경우.
        <br />
        <br />

        <h3>준거법</h3>
        본 이용 약관은 당사가 위치한 관할권의 법률에 따라 규율되고 해석됩니다.
      </div>
    </div>
  );
}

export default Terms;
