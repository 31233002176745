import axios from "axios";
import { googleLogout } from "@react-oauth/google";
import Swal from "sweetalert2";

function DeleteAccount(props) {
  async function delete_account() {
    try {
      if (!props.checked) {
        Swal.fire({
          html: `<div>You must agree to the terms before continue.</div>`,
        });
        return;
      }
      props.setOnProcess(true);
      const res = await axios
        .post(`${process.env.REACT_APP_ARTSHARING_USER_SERVER}/delete_by_web`,
          props.client_info,
        )
        .then((res) => {
          props.setOnProcess(false);
          return res;
        })
        .catch((err) => {
          throw err;
        });
      if (res.data.success) {
        try {
          googleLogout();
        } catch (err) {
        }
        Swal.fire({
          html: `<div style="text-align: left; padding: 0 30px">
            Your Account Has Been Successfully Deleted.
            <br />
            <br />
            We are grateful for your support and trust in our service. Your
            account has been successfully deleted, and all related data has been
            removed from our system.
            <br />
            <br />
            You don't need to take any further action. Your experience with us
            has been valuable, and we hope to serve you again.
            <br />
            <br />
            If you have any questions or need assistance, please don't hesitate
            to contact our support team at mippylabs@gmail.com. Thank you once
            again for being a part of our community.
            <br />
            <br />
            Sincerely,
            <br />
            Art Sharing Team
          </div> `,
        }).then(() => {
          props.setChecked();
          props.afterDelete();
        });
      } else {
        Swal.fire("Account not found! Please sign up first!");
      }
    } catch (e) {
      Swal.fire("Account not found! Please sign up first!");
    }
  }

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
        }}
      >
        <input
          style={{
            cursor: "pointer",
          }}
          type={"checkbox"}
          checked={props.checked}
          onChange={() => {
            props.setChecked(!props.checked);
          }}
        />
        <div
          style={{
            padding: "0 0 0 10px",
          }}
        >
          I understand that deleting my account will permanently remove all my
          data, and it cannot be recovered.
        </div>
      </div>
      <div
        style={{
          padding: "30px 0 0 0",
        }}
      >
        <button
          style={{
            padding: "10px 20px",
            background: "red",
            border: "1px solid red",
            cursor: "pointer",
          }}
          onClick={delete_account}
        >
          Delete my Account
        </button>
      </div>
    </div>
  );
}

export default DeleteAccount;
