function TermsEn() {
  return (
    <div
      style={{
        textAlign: "center",
        padding: "10px 50px 50px",
      }}
    >
      <div
        style={{
          textAlign: "left",
          color: "white",
          textDecoration: "none",
        }}
      >
        <a
          href={"/terms"}
          style={{
            color: "white",
            textDecoration: "none",
          }}
        >
          한국어
        </a>
      </div>
      <h1>Terms & Conditions</h1>
      <div
        style={{
          textAlign: "left",
        }}
      >
        Welcome to Art Sharing (the "App"). By accessing or using the App, you agree to be bound by the following terms and conditions ("Terms and
        Conditions"). If you do not agree to these Terms and Conditions, you may not use the App.
        <br />
        <br />

        <h3>Collection of Information</h3>
        The App may collect certain information from you, including your username, email address, profile picture, and location data. We use this
        information to provide you with personalized features and services within the App. We may also use Google Analytics to collect user
        information, such as device type, operating system, and usage statistics. This information helps us improve the App and provide better
        services to our users.
        <br />
        <br />

        <h3>Use of Information</h3>
        We may use the information we collect to improve and optimize the App's functionality, provide a better user experience, and develop new
        features and services. We may also use the information to communicate with you about updates, promotions, and other relevant information
        related to the App.
        <br />
        <br />

        <h3>Disclosure of Information</h3>
        We do not sell or share your personal information with third parties without your consent. However, we may disclose your information if
        required by law, court order, or governmental agency.
        <br />
        <br />

        <h3>Security</h3>
        We take the security of your information seriously and take reasonable measures to protect your information from unauthorized access or
        disclosure. However, no method of transmission over the Internet or electronic storage is completely secure, and we cannot guarantee the
        absolute security of your information.
        <br />
        <br />

        <h3>Changes to the Terms and Conditions</h3>
        We may modify these Terms and Conditions at any time without prior notice. Your continued use of the App after such modifications will
        constitute your acceptance of the revised Terms and Conditions.
        <br />
        <br />

        <h3>Disclaimer</h3>
        The App is provided on an "as is" basis, and we make no representations or warranties of any kind, express or implied, regarding the App's
        availability, accuracy, reliability, or suitability for any purpose. We do not warrant that the App will be error-free or uninterrupted.
        <br />
        <br />

        <h3>Limitation of Liability</h3>
        In no event shall we be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with
        the use of the App, including but not limited to loss of profits, data, or other intangible losses, even if we have been advised of the
        possibility of such damages.
        <br />
        <br />

        <h3>Governing Law</h3>
        These Terms and Conditions shall be governed by and construed in accordance with the laws of the jurisdiction in which we are located.
      </div>
    </div>
  );
}

export default TermsEn;
