function PrivacyEn() {
  return (
    <div
      style={{
        textAlign: "center",
        padding: "10px 50px 50px",
      }}
    >
      <div
        style={{
          textAlign: "left",
          color: "white",
          textDecoration: "none",
        }}
      >
        <a
          href={"/privacy"}
          style={{
            color: "white",
            textDecoration: "none",
          }}
        >
          한국어
        </a>
      </div>
      <h1>Privacy Policy</h1>
      <div
        style={{
          textAlign: "left",
        }}
      >
        Welcome to Art Sharing (the "App"). This Privacy Policy describes how we collect, use, and protect your personal information. By accessing or
        using the App, you consent to the collection, use, and disclosure of your personal information in accordance with this Privacy Policy.
        <br />
        <br />

        <h3>Collection of Information</h3>
        We may collect certain information from you, including your username, email address, profile picture, and location data. We use this
        information to provide you with personalized features and services within the App.

        We may also use Google Analytics to collect user information, such as device type, operating system, and usage statistics. This information
        helps us improve the App and provide better services to our users.
        <br />
        <br />

        <h3>Use of Information</h3>
        We may use the information we collect to improve and optimize the App's functionality, provide better user experience, and develop new
        features and services. We may also use the information to communicate with you about updates, promotions, and other relevant information
        related to the App.
        <br />
        <br />

        <h3>Disclosure of Information</h3>
        We do not sell or share your personal information with third parties without your consent. However, we may disclose your information if
        required by law, court order, or governmental agency.
        <br />
        <br />

        <h3>Security</h3>
        We take the security of your information seriously and take reasonable measures to protect your information from unauthorized access or
        disclosure. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee the
        absolute security of your information.
        <br />
        <br />

        <h3>Third-Party Links</h3>
        The App may contain links to third-party websites or services that are not owned or controlled by us. We are not responsible for the privacy
        practices or content of these third-party websites or services, and we encourage you to review their respective privacy policies.
        <br />
        <br />

        <h3>Children's Privacy</h3>
        The App is not intended for children under the age of 13. We do not knowingly collect personal information from children under the age of 13.
        If you are a parent or guardian and believe that your child has provided us with personal information, please contact us at the email address
        provided below.
        <br />
        <br />

        <h3>Changes to the Privacy Policy</h3>
        We may modify this Privacy Policy at any time without prior notice. Your continued use of the App after such modifications will constitute
        your acceptance of the revised Privacy Policy.
        <br />
        <br />

        <h3>Contact Us</h3>
        If you have any questions or concerns about this Privacy Policy, or if you would like to exercise your privacy rights, please contact us at
        [mippylabs@gmail.com].
      </div>
    </div>
  );
}

export default PrivacyEn;
